<template>
  <div class="page-content">
    <div class="container-fluid pl-0">
      <breadcrumb class="breadcrumb" :goBack="true" :content="breadcrumb"></breadcrumb>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-6">
          <form class="edit mb-5 mt-4" action="#!">
            <div class="form-group title-input">
              <label>Name of your watch list</label>
              <input type="text" class="form-control title" v-model="form.name" @blur="validateListTitle" placeholder="Tell us more about your watchlist...">
              <span class="validation-error" v-show="showListNameError">Enter a name for the list.</span>
            </div>
            <div class="form-group description-input">
              <label>Description (optional)</label>
              <textarea cols="30" rows="6" class="form-control" v-model="form.description" placeholder="Tell us more about your watchlist..."></textarea>
            </div>
            <button class="btn btn-hover mt-5 d-block mx-auto save-changes" @click="dispatchUpdateCollection">Save</button>
          </form>
        </div>
      </div>
      <h2 class="content-header-text" v-if="contents!==null">Contents of your watch list</h2>
      <div class="row card-container">
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 card-style d-flex align-items-stretch" v-if="contents!==null" v-for="(content, index) in contents" :key="index">
          <angebot-card v-if="content.content_type_name=='angebot'" :item="content"></angebot-card>
          <ort-card v-if="content.content_type_name=='institution'" :item="content" :autoWidth="true"></ort-card>
        </div>
      </div>

      <p v-if="false">Tip: Change the order using drag and drop</p>
      <div class="footer-margin"></div>
    </div>

    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';
  import Resource from '@/api/resource';
  const collectionResource = new Resource('collections');
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';

  export default {
    name: 'EditMerkliste',
    components: {
      AngebotCard: () => import('@/components/cards/Angebot.vue'),
      OrtCard: () => import('@/components/cards/Ort.vue'),
      Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
    },
    data: () => ({
      collection: null,
      showListNameError: false,
      form: {
        name: "",
        description: '',
      },
    }),
    created(){
      this.getCollection();
    },
    computed: {
      breadcrumb() {
        return "Merkliste bearbeiten" + " | " + this.title; 
      },
      id() {
        return this.$route.params.id;
      },
      title(){
        if(this.collection!==null){
          return this.collection.name;
        }
        return null;
      },
      contents(){
        if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
          return this.collection.contents;
        }
        return null;
      },
    },
    methods: {
      showLoader,
      hideLoader,
      validateListTitle(){
        if(!this.form.name!=""){
          this.showListNameError=true;
          return false;
        }
        else{
          this.showListNameError=false;
          return true;
        }
      },
      getCollection() {
        this.loader = this.showLoader(this.loader);
        collectionResource.get(this.id)
        .then(response => {
          this.collection = response.data;
          this.form.name = this.collection.name;
          if(this.collection.description !== null){
            this.form.description = this.collection.description;
          }
          this.form.type = this.collection.type;
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Keine Berechtigung',
            text: 'Sie haben nicht die benötigten Berechtigungen um diese Liste einzusehen.'
          });

          if(this.user==null){
            this.$router.push({name:'login'});
          }

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
        });
      },
      dispatchUpdateCollection(e) {
        e.preventDefault();
        if(!this.validateListTitle()){
          return;
        }

        this.loader = this.showLoader(this.loader);

        collectionResource.update(this.collection.id,this.form).then(() => {
          this.$notify({
            duration: 2500,
            title: 'Liste umbenannt!',
            text: 'Die Liste wurde erfolgreich bearbeitet.'
          });

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
          this.$router.push({ name: 'MerklisteDetail', params: {id: this.id } });
        });
      },
    }
  }
</script>
<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content {
    background-color: #FFF;
    margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});
  }

  .card-style {
    padding-bottom: 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;

    @media (max-width: 400px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    & a {
      height: 100%;
      width: 100%;
      min-width: 100px;
    }
  }

  .card {
    margin-left: 5px !important;
  }

  .content-header-text {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .title-input, .description-input {
    display: block;
  }

  .title-input {
    margin-bottom: 30px;
  }

  .validation-error {
    color: red;
  }

  .breadcrumb {
    width: 30% !important;
  }

  .card-container {
    padding-bottom: 50px !important;
  }
</style>